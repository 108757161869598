import avatar_img from "@/public/onimisea.svg";
import { RxChevronDown } from "react-icons/rx";
import Link from "next/link";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Image from "next/image";
import { axiosInstance } from "@/lib/constants";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import Loader from "./ui/Loader";
import { UserAuth } from "@/context/AuthContext";

export function AvatarDropdown({ userProfile }) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const pathname = usePathname();

  const { logOut: gaLogoutFnc } = UserAuth();
  // Google auth logout
  const gaLogout = async () => {
    try {
      await gaLogoutFnc();
      localStorage.removeItem("gaAccessToken");
      localStorage.removeItem("userInfo");

      if (pathname === "/") {
        window.location.reload();
      } else {
        router.push("/");
      }
    } catch (error) {}
  };
  // Normal auth logout
  const Logout = () => {
    setLoading(true);
    axiosInstance
      .get("auth/logout")
      .then((res) => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("accessToken");
        setLoading(false);
        if (pathname === "/") {
          window.location.reload();
        } else {
          router.push("/");
        }
      })
      .catch((err) => {
        setLoading(false);
        localStorage.removeItem("userInfo");
        localStorage.removeItem("accessToken");
        if (pathname === "/") {
          window.location.reload();
        } else {
          router.push("/");
        }
      });
  };
  const SignOut = () => {
    const userInfo = localStorage.getItem("userInfo");
    const accessToken = localStorage.getItem("accessToken");
    const gaAccessToken = localStorage.getItem("gaAccessToken");
    if (userInfo && accessToken) {
      Logout();
    } else {
      gaLogout();
    }
  };
  return (
    <DropdownMenu>
      {userProfile ? (
        <DropdownMenuTrigger className="cursor-pointer " asChild>
          <section className="flex items-center justify-center  select-none">
            <div className="relative w-12 h-12  border border-primary-blue rounded-full overflow-hidden cursor-pointer ">
              {!avatar_img ? (
                <Image
                  src={avatar_img}
                  alt="Merchant Name"
                  width={50}
                  height={50}
                  className="object-cover w-full h-full"
                  priority
                />
              ) : (
                <p className="absolute inset-0 flex items-center justify-center text-lg font-bold uppercase">
                  {userProfile.firstName[0]} {userProfile.lastName[0]}
                </p>
              )}
            </div>

            <div className="  hidden sm:flex items-center justify-center gap-3 pl-2">
              <div className="">
                <p className=" text-xs md2::text-sm">
                  {userProfile.businessName}
                </p>
                <p className="text-sm md2::text-lg ">
                  {userProfile.firstName} {userProfile.lastName}
                </p>
              </div>
              <RxChevronDown size={25} className="text-slate-500" />
            </div>
          </section>
        </DropdownMenuTrigger>
      ) : (
        <DropdownMenuTrigger className="cursor-pointer border rounded-full focus:outline-none md:hidden">
          <RxChevronDown size={25} className="text-slate-500  " />
        </DropdownMenuTrigger>
      )}

      <DropdownMenuContent className="w-40 p-2">
        {/* <DropdownMenuItem className="py-3">
          Profile
          <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
        </DropdownMenuItem> */}
        <div className="flex  flex-col border ">
          {!userProfile && (
            <>
              <hr />
              <Link
                className=" p-2 mt-4 border-b md:hidden  "
                href="/auth/register"
              >
                Register
              </Link>
              <hr />
              <Link className=" p-2  border-b md:hidden " href="/auth/login">
                Login
              </Link>
              <Link className=" p-2 border-b md2:hidden " href="/">
                Home
              </Link>
              <Link className=" p-2 border-b md2:hidden  " href="/pricing">
                Pricing
              </Link>
              <Link className=" p-2 border-b md2:hidden  " href="/about-us">
                About Us
              </Link>
              <Link
                className=" p-2 border-b md2:hidden  "
                href="https://centiiv.gitbook.io/centiiv/"
              >
                Docs
              </Link>
            </>
          )}

          {userProfile && (
            <>
              {" "}
              <Link className=" p-2  " href="/dashboard">
                Dashboard
              </Link>
              <hr />
              <Link className=" p-2 " href="/account/general">
                Account
              </Link>
              <hr />
              <button
                className="flex justify-between items-center p-2 "
                onClick={SignOut}
              >
                {loading ? (
                  <div className="w-fit mx-auto">
                    <Loader />
                  </div>
                ) : (
                  "Logout"
                )}
              </button>
            </>
          )}
        </div>

        {/* <DropdownMenuItem className="py-3">
          Billing
          <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem className="py-3">
          Settings
          <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem className="py-3">
          Orders <DropdownMenuShortcut>⌘O</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem className="py-3">
          Invoices <DropdownMenuShortcut>⌘I</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          Support Tickets <DropdownMenuShortcut>⌘ST</DropdownMenuShortcut>
        </DropdownMenuItem> */}
        {/* <DropdownMenuSeparator /> */}
        {/* <DropdownMenuItem onClick className="py-3">
          TYTY
        </DropdownMenuItem> */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
