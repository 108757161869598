"use client";
import { formatDateString, getNewAccessToken } from "@/lib/utils";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationEllipsis,
} from "@/components/ui/pagination";
import { useRouter } from "next/navigation";
import { axiosInstance } from "@/lib/constants";
import { useEffect, useState } from "react";
import Loader from "./ui/Loader";
import { ChevronLeft, ChevronRight } from "lucide-react";

function NotificationModal({ hideNotificationModalFunc }) {
  const [currentNotificationPage, setCurrentNotificationPage] = useState(1);
  const [notifications, setNotifications] = useState(null);

  const [status, setStatus] = useState("loading");

  const notificationPerPage = 10;

  const getNotifications = async (page) => {
    try {
      await getNewAccessToken();
      const res = await axiosInstance.get(
        `/dashboard/notifications?page=${page}&pageSize=${notificationPerPage}`
      );

      console.log(res.data.data);
      setNotifications(res.data.data);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  };
  useEffect(() => {
    getNotifications(currentNotificationPage);
  }, [currentNotificationPage]);

  //   if(!notifications) return
  return (
    <div className="fixed z-[200] text-black top-0 right-0 bottom-0 left-0 md:left-1/2 bg-white/90 backdrop-blur-md md:rounded-l-xl overflow-y-scroll  py-6  border-border rounded-xl border">
      <div className="  w-full">
        <div className=" px-4  flex flex-col justify-between leading-normal">
          <div className="">
            <div className="flex justify-between mb-6 px-6">
              <p className="  flex items-center text-center w-full font-bold text-xl mb-2">
                Notifications
              </p>

              <button className="w-fit" onClick={hideNotificationModalFunc}>
                Back
              </button>
            </div>

            {notifications && (
              <>
                {" "}
                {notifications?.length === 0 ? (
                  <div>There are currently no notifications</div>
                ) : (
                  <>
                    <div className="relative   flex flex-col gap-8">
                      {notifications.map((notification, index) => (
                        <div
                          key={index}
                          className="  flex items-center text-sm  gap-6 px-4 "
                        >
                          <div className="flex gap-2 items-start ">
                            <span className="text-xl font-semibold -mt-1">
                              •
                            </span>
                            <span>
                              {notification.message} at{" "}
                              {formatDateString(notification.createdAt)}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <PaginationComponent
                  currentPage={currentPage}
                  moreData={
                    paymentLinksData?.data?.length >= linksPerPage
                      ? true
                      : false
                  }
                /> */}
                  </>
                )}
              </>
            )}
            {status === "loading" && (
              <div className="mt-12">
                {" "}
                <Loader />
              </div>
            )}
            {status === "error" && (
              <div className="mt-12">Error Fetching Notifications</div>
            )}
            {notifications && notifications.length > 0 && (
              <PaginationComponent
                currentPage={currentNotificationPage}
                setCurrentNotificationPage={setCurrentNotificationPage}
                moreData={
                  notifications?.length >= notificationPerPage ? true : false
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationModal;

const PaginationComponent = ({
  currentPage,
  setCurrentNotificationPage,
  moreData,
}) => {
  return (
    <div className=" flex justify-center mt-8  mb-4">
      <Pagination>
        <PaginationContent>
          {currentPage !== 1 && (
            <PaginationItem>
              <button
                className="gap-1 pl-2.5 flex items-center"
                onClick={() =>
                  setCurrentNotificationPage(
                    currentPage > 1 ? currentPage - 1 : 1
                  )
                }
              >
                <ChevronLeft className="h-4 w-4" />
                <span className="sm2:inline-block hidden">Previous</span>
              </button>
            </PaginationItem>
          )}
          <PaginationItem>
            {currentPage > 1 && (
              <button
                onClick={() => setCurrentNotificationPage(currentPage - 1)}
              >
                {currentPage - 1}
              </button>
            )}
          </PaginationItem>
          <PaginationItem>
            <button className="border rounded-sm p-2">{currentPage}</button>
          </PaginationItem>
          {moreData && (
            <PaginationItem>
              <button
                onClick={() => setCurrentNotificationPage(currentPage + 1)}
              >
                {currentPage + 1}
              </button>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
          {moreData && (
            <PaginationItem>
              <button
                className="gap-1 pl-2.5 flex items-center"
                onClick={() => setCurrentNotificationPage(currentPage + 1)}
              >
                <span className="sm2:inline-block hidden">Next</span>
                <ChevronRight className="h-4 w-4" />
              </button>
            </PaginationItem>
          )}
        </PaginationContent>
      </Pagination>
    </div>
  );
};
